<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-row>
        <a-col :span="24">
          <!-- 车辆状态视图 -->
          <truck-icon-list
            :carNumber1="carNumber1"
            :carNumber2="carNumber2"
            :carNumber3="carNumber3"
            :carNumber4="carNumber4"
            :carNumber5="carNumber5"
          />
        </a-col>
      </a-row>
      <a-row style="margin-top: 20px">
        <a-col :span="4">
          <!-- 机构树 -->
          <dept-tree ref="deptTree" :deptOptions="deptOptions" @select="clickDeptNode" />
        </a-col>
        <a-col :span="20" style="border-left: 1px solid #e8e8e8">
          <a-row style="margin-left: 10px; margin-top: 15px">
            <a-col :span="24">
              <!-- 条件搜索 -->
              <div class="table-page-search-wrapper">
                <a-form layout="inline">
                  <a-row :gutter="48">
                    <a-col :md="8" :sm="24">
                      <a-form-item label="车辆编号" prop="vehicleId">
                        <a-input v-model="queryParam.vehicleId" placeholder="请输入车辆编号" allow-clear />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="车辆内部ID" prop="vehicleInnerId">
                        <a-input v-model="queryParam.vehicleInnerId" placeholder="请输入车辆内部ID" allow-clear />
                      </a-form-item>
                    </a-col>
                    <template v-if="advanced">
                      <a-col :md="8" :sm="24">
                        <a-form-item label="MAC地址" prop="mqttTopic">
                          <a-input v-model="queryParam.mqttTopic" placeholder="请输入MAC地址" allow-clear />
                        </a-form-item>
                      </a-col>
                      <a-col :md="8" :sm="24">
                        <a-form-item label="车辆名称" prop="name">
                          <a-input v-model="queryParam.name" placeholder="请输入车辆名称" allow-clear />
                        </a-form-item>
                      </a-col>
                    </template>
                    <a-col :md="(!advanced && 8) || 24" :sm="24">
                      <span
                        class="table-page-search-submitButtons"
                        :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
                      >
                        <a-button
                          type="primary"
                          @click="handleQuery"
                          id="tableQueryBtn"
                        ><a-icon type="search" />查询</a-button
                        >
                        <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                        <a @click="toggleAdvanced" style="margin-left: 8px">
                          {{ advanced ? '收起' : '展开' }}
                          <a-icon :type="advanced ? 'up' : 'down'" />
                        </a>
                      </span>
                    </a-col>
                  </a-row>
                </a-form>
              </div>
            </a-col>
          </a-row>

          <!-- 操作 -->
          <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
            <div class="table-operations" style="margin-left: 10px">
              <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:iot:vehicle:add']">
                <a-icon type="plus" />新增
              </a-button>
              <a-button
                type="primary"
                :disabled="single"
                @click="$refs.createForm.handleUpdate(undefined, ids)"
                v-hasPermi="['tenant:iot:vehicle:edit']"
              >
                <a-icon type="edit" />修改
              </a-button>
              <a-button
                type="danger"
                :disabled="multiple"
                @click="handleDelete"
                v-hasPermi="['tenant:iot:vehicle:remove']"
              >
                <a-icon type="delete" />删除
              </a-button>
              <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:vehicle:export']">
                <a-icon type="download" />导出
              </a-button>
              <!-- <a-button
                type="dashed"
                shape="circle"
                :loading="loading"
                :style="{ float: 'right' }"
                icon="reload"
                @click="getList"
              /> -->
            </div>
          </a-toolbar>
          <!-- 增加修改 -->
          <create-form
            ref="createForm"
            :vehicleCategoryOptions="vehicleCategoryOptions"
            :deptOptions="deptOptions"
            :automodeOptions="automodeOptions"
            :vehicleStatusOptions="vehicleStatusOptions"
            @ok="getList"
          />
          <!-- 数据展示 -->
          <a-table
            :loading="loading"
            :size="tableSize"
            tid="1"
            rowKey="id"
            :columns="columns"
            :data-source="list"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :pagination="false"
          >
            <span slot="vehicleCategory" slot-scope="text, record">
              {{ vehicleCategoryFormat(record) }}
            </span>
            <span slot="operation" slot-scope="text, record">
              <a href="javascript:;" @click="handleCheckRegMac(record)">车辆注册 </a>
              <a-divider type="vertical" />
              <a href="javascript:;" @click="goToAbility(record)" v-hasPermi="['tenant:iot:vehicleAbility:list']">
                车辆能力
              </a>
              <a-divider
                type="vertical"
                v-hasPermi="['tenant:iot:vehicle:edit']"
                v-if="record.operatorId === sessionUserId"
              />
              <a
                href="javascript:;"
                @click="$refs.createForm.handleUpdate(record, undefined)"
                v-hasPermi="['tenant:iot:vehicle:edit']"
              >
                <a-icon type="edit" />修改
              </a>
              <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicle:remove']" />
              <a href="javascript:;" @click="handleDelete(record)" v-hasPermi="['tenant:iot:vehicle:remove']">
                <a-icon type="delete" />删除
              </a>
              <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicle:remove']" />
              <a href="javascript:;" @click="handleToUpkeep(record)" v-hasPermi="['tenant:iot:vehicle:edit']">
                保养信息
              </a>
            </span>
            <template slot="onlineText" slot-scope="text">
              <online-status :text="text" />
            </template>
            <template slot="enabledText" slot-scope="text, record">
              <span v-if="record.enabled">正常</span>
              <span v-else>停用</span>
            </template>
            <template slot="soc" slot-scope="text">
              <battery-status :text="text" />
            </template>
          </a-table>
          <!-- 分页 -->
          <a-pagination
            class="ant-table-pagination"
            show-size-changer
            show-quick-jumper
            :current="queryParam.pageNum"
            :total="total"
            :page-size="queryParam.pageSize"
            :showTotal="(total) => `共 ${total} 条`"
            @showSizeChange="onShowSizeChange"
            @change="changeSize"
          />
        </a-col>
      </a-row>
    </a-card>

    <vxe-modal
      v-model="isCheckingRegMac"
      title="车辆注册"
      width="500"
      height="300"
      :show-zoom="false"
      resize
      @close="isCheckingRegMac = false"
      :position="{ top: '30%', left: '45%' }"
    >
      <a-spin :tip="tips" :spinning="spinning" v-if="spinning">
        <div class="spin-content"></div>
      </a-spin>
      <div v-else class="spin-result">{{ tips }}</div>
    </vxe-modal>
  </page-header-wrapper>
</template>

<script>
import { delVehicle, exportVehicle, vlistVehicle, checkRegMac, getVehicle } from '@/api/system/vehicle'
import { treeselect } from '@/api/system/dept'
import DeptTree from './modules/DeptTree'
import { ackData } from '@/translatedata/systemData.js'
import CreateForm from './modules/CreateForm'
import TruckIconList from './modules/TruckIconList'
import BatteryStatus from './modules/BatteryStatus'
import OnlineStatus from './modules/OnlineStatus'
import { mapState, mapGetters } from 'vuex'
import allIcons from '@/core/icons'

export default {
  name: 'Vehicle',
  components: {
    CreateForm,
    TruckIconList,
    BatteryStatus,
    OnlineStatus,
    allIcons,
    DeptTree
  },
  data() {
    return {
      isCheckingRegMac: false,
      spinning: false,
      tips: '',
      // 获取状态超时设置(毫秒)
      getStatusTimeout: 10 * 1000,
      monitorIcon: allIcons.monitorIcon,
      list: [],
      statusType: -1,
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      vehicleIdList: [],
      loopTimer: null,
      dropTimeOut: null,
      timeOut: {},
      loading: false,
      total: 0,
      // 车辆类别字典
      vehicleCategoryOptions: [],
      // 是否自动:0非自动，1自动字典
      automodeOptions: [],
      // 状态：1在线，0下线
      vehicleStatusOptions: [],
      // 机构树选项
      deptOptions: [
        {
          id: 0,
          label: '',
          children: []
        }
      ],
      // 查询参数
      queryParam: {
        vehicleId: null,
        pageNum: 1,
        pageSize: 10,
        deptId: undefined,
        vehicleInnerId: undefined
      },
      mapWorkStatus: {
        0: '未定义',
        1: '待命中',
        2: '作业中',
        3: '充电中',
        4: '维修中'
      },
      columns: [
        {
          title: '车辆编号',
          dataIndex: 'vehicleId',
          ellipsis: true,
          align: 'center',
          width: 80
        },
        {
          title: '车辆名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆内部ID',
          dataIndex: 'vehicleInnerId',
          ellipsis: true,
          align: 'center',
          width: 120
        },
        {
          title: '车端MAC地址',
          dataIndex: 'mqttTopic',
          ellipsis: true,
          align: 'center',
          width: 120
        },
        {
          title: '所属机构',
          dataIndex: 'deptName',
          width: '10%',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆类别',
          dataIndex: 'vehicleCategory',
          scopedSlots: { customRender: 'vehicleCategory' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '在线状态',
          dataIndex: 'onlineText',
          key: 'onlineText',
          align: 'center',
          scopedSlots: { customRender: 'onlineText' }
        },
        {
          title: '电量',
          dataIndex: 'soc',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'soc' }
        },
        {
          title: '车辆状态',
          dataIndex: 'enabledText',
          key: 'enabledText',
          align: 'center',
          scopedSlots: { customRender: 'enabledText' },
          width: 80
        },
        {
          title: '注册状态',
          dataIndex: 'regCheckMac',
          key: 'regCheckMac',
          align: 'center',
          customRender: (text, record, index) => {
            const status = record.regCheckMac
            if (status === 0) {
              return '未注册'
            } else if (status === 1) {
              return '已注册'
            } else if (status === 100) {
              return '请求内容错误'
            } else if (status === 101) {
              return 'Mac地址不一致'
            } else {
              return ''
            }
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '25%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      tabList: [
        {
          key: -1,
          title: 'all',
          dataSource: []
        },
        {
          key: 5,
          title: 'undefined', // 未知
          dataSource: []
        },
        {
          key: 1,
          title: 'online', // 在线中
          dataSource: []
        },
        {
          key: 0,
          title: 'free', // 空闲中
          dataSource: []
        },
        {
          key: 4,
          title: 'task', // 任务中
          dataSource: []
        },
        {
          key: 2,
          title: 'request', // 托管中
          dataSource: []
        }
      ]
    }
  },
  filters: {},
  watch: {
    ackData(newVal, oldVal) {
      // console.log('newVal', newVal)
      this.$notification.info({
        message: newVal.vehicleId,
        description: newVal.type + ackData.result[newVal.data.result]
      })
      this.timeOut[newVal.vehicleId] && clearTimeout(this.timeOut[newVal.vehicleId])
      this.getCarList()
    },
    inputValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.current = 1
      }
    }
  },
  created() {
    this.$store.dispatch('websocketConnect')
    this.getList()
    this.getTreeselect()
    this.getDicts('iot_vehicle_category').then((response) => {
      this.vehicleCategoryOptions = response.data
    })
    this.getDicts('iot_vehicle_automode').then((response) => {
      this.automodeOptions = response.data
    })
    this.getDicts('iot_vehicle_status').then((response) => {
      this.vehicleStatusOptions = response.data
    })
    this.loopTimer && clearInterval(this.loopTimer)
    this.loopTimer = setInterval(() => {
      this.getList(true)
      console.log('list loopTimer====', this.loopTimer)
    }, 15000)
  },
  deactivated() {
    this.loopTimer && clearInterval(this.loopTimer)
    console.log('list remove loopTimer====', this.loopTimer)
    this.timeOut = {}
    this.$store.dispatch('stopWebSocket')
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapState({
      ackData: (state) => state.websocket.ackData
    }),
    ...mapGetters(['sessionUserId']),
    carNumber1() {
      return this.tabList.filter((m) => m.key === 1)[0].dataSource.length
    },
    carNumber2() {
      return this.tabList.filter((m) => m.key === 0)[0].dataSource.length
    },
    carNumber3() {
      return this.tabList.filter((m) => m.key === 4)[0].dataSource.length
    },
    carNumber4() {
      return this.tabList.filter((m) => m.key === 2)[0].dataSource.length
    },
    carNumber5() {
      return this.tabList.filter((m) => m.key === 5)[0].dataSource.length
    }
  },
  methods: {
    // 是否自动:0非自动，1自动字典翻译
    automodeFormat(row, column) {
      return this.selectDictLabel(this.automodeOptions, row.automode)
    },
    // 车辆类别字典翻译
    vehicleCategoryFormat(row, column) {
      return this.selectDictLabel(this.vehicleCategoryOptions, row.vehicleCategory)
    },
    /** 查询机构下拉树结构 */
    getTreeselect() {
      treeselect().then((response) => {
        this.deptOptions = response.data
      })
    },
    /** 查询车辆管理列表 */
    getList(isLoop) {
      if (!isLoop) {
        this.loading = true
      }
      this.vehicleIdList = []
      vlistVehicle(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
        this.list = this.list.map((item) => {
          // console.log('item', item)
          this.vehicleIdList.push(item.vehicleId)
          return {
            ...item,
            soc: item.soc || item.soc === 0 ? item.soc : item.soc,
            value: item.operatorId || undefined,
            onlineText: item.online,
            action: item.workStatus,
            btnLoading: false
          }
        })
        this.filterDataList()
        if (!isLoop) {
          this.$store.dispatch('websocketSubSend', { ids: this.vehicleIdList, type: 'tos' })
        }
      })
    },
    filterDataList() {
      this.tabList[0].dataSource = this.list
      this.tabList.map((item) => {
        if (item.key !== -1 && item.key !== 1 && item.key !== 0 && item.key !== 5 && item.key !== 2) {
          item.dataSource = this.list.filter((m) => m.workStatus === item.key)
        }
        if (item.key === 1) {
          // 在线
          item.dataSource = this.list.filter((m) => m.online)
        }
        if (item.key === 5) {
          // 离线
          item.dataSource = this.list.filter((m) => !m.online)
        }
        if (item.key === 0) {
          // 空闲中
          item.dataSource = this.list.filter((m) => m.online && m.workStatus === 0)
        }
        if (item.key === 2) {
          // 托管中
          item.dataSource = this.list.filter((m) => m.managedRequestStatus === 1)
        }
      })
      this.filterData = this.tabList.filter((item) => item.key === this.statusType)[0].dataSource
      if (this.queryParam.vehicleId) {
        this.filterData = this.tabList
          .filter((item) => item.key === this.statusType)[0]
          .dataSource.filter((m) => m.vehicleId.indexOf(this.queryParam.vehicleId) > -1)
      }
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        vehicleId: undefined,
        pageNum: 1,
        pageSize: 10,
        deptId: undefined,
        vehicleInnerId: undefined
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    goToMonitor(row) {
      this.$router.push('/device/monitor/vehicle?id=' + row.vehicleId)
    },
    clickDeptNode(deptId) {
      this.queryParam.deptId = deptId
      this.getList()
    },
    goToAbility(row) {
      this.$router.push('/device/vehicleAbility?vehicleId=' + row.vehicleId)
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中车辆编号为' + row.vehicleId + '的数据',
        onOk() {
          return delVehicle(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportVehicle(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    // 检测通讯
    handleCheckRegMac(record) {
      checkRegMac(record.id).then(() => {
        // 弹窗获取状态
        this.tips = '正在进行车辆注册'
        this.isCheckingRegMac = true
        this.spinning = true
        this.isClearTimeout = false
        this.getStatusStartTickcount = new Date()
        this.getConnectResult(record.id)
      })
    },
    // 获取检测通讯结果
    getConnectResult(id) {
      if (this.isClearTimeout) {
        // 退出定时器任务
        console.log('退出定时器任务')
        return
      }
      this.getStatusTimeoutId && clearTimeout(this.getStatusTimeoutId)
      this.getStatusTimeoutId = setTimeout(() => {
        getVehicle(id)
          .then((response) => {
            const data = response.data
            const result = data.result

            if (result === 0) {
              this.tips = '车辆注册成功'
              // 结束
              this.toEnd('success', this.tips)
            } else if (data.result === 888) {
              // 继续获取状态
              const curDateTime = new Date()
              const tickcount = curDateTime.getTime() - this.getStatusStartTickcount.getTime()
              if (tickcount <= this.getStatusTimeout) {
                this.getConnectResult(id)
              } else {
                this.tips = '车辆注册超时'
                // 结束
                this.toEnd('warn', this.tips)
              }
              this.getConnectResult(id)
            } else if (result === 101) {
              this.tips = '车辆注册异常，请确认中控车辆内部ID和MAC地址是否与车端一致'
              // 结束
              this.toEnd('error', this.tips)
            } else {
              this.tips = '未知错误，请联系管理员'
              // 结束
              this.toEnd('error', this.tips)
            }
          })
          .catch((e) => {
            this.tips = e.message
            this.toEnd('error', this.tips)
          })
      }, 3000)
    },
    toEnd(infoType, result) {
      if (!this.spinning && !this.isCheckingRegMac) {
        return
      }
      this.spinning = false
      this.isCheckingRegMac = false
      let myModal = this.$successEx
      if (infoType === 'warn') {
        myModal = this.$warningEx
      } else if (infoType === 'error') {
        myModal = this.$errorEx
      }
      myModal(result, () => {
        this.clearTimeoutEx()
      })
    },
    clearTimeoutEx() {
      console.log('clearTimeoutEx')
      this.getStatusTimeoutId && clearTimeout(this.getStatusTimeoutId)
    },
    handleToUpkeep(record) {
      this.$router.push({ path: '/device/upkeep?vehicleId=' + record.vehicleId })
    }
  },
  beforeDestroy() {
    this.clearTimeoutEx()
  }
}
</script>
<style lang="less" scoped>
.spin-result {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
